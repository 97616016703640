.welcome-card img {
  max-height: 35vh;
  overflow: hidden;
}
ion-slide >:first-child {
  width: 100%;
}
ion-select {
  max-width: 100% !important;
}
