.logo {
  height: 61;
  width: 149;
}

.inputlogin {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid rgb(199, 198, 198);
  border-radius: 6px;
}

input:-internal-autofill-selected {
  background-color: rgb(204, 255, 36) !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important;
}